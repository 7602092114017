import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from 'components/ui-components-v2/Menu';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import EditorData from 'components/editor-data/EditorData';
import Comments from 'components/social/Comments';
import CommentReviewHeader from 'components/social/CommentReviewHeader';
import Alert from 'components/ui-components-v2/Alert';
import EditorSubsets from 'components/editor-base/EditorSubsets';
import Translation from 'components/data/Translation';
import TemplateHelpers from 'components/data/TemplateHelpers';
import Typography from 'components/ui-components-v2/Typography';
import { isAMV2Enabled, isTemplateDesignerTemplateType } from 'components/template-management/utilities';
import User from 'components/data/User';
import Templates from 'components/data/Templates';
import RouteHelper from 'components/ui-base/RouteHelper';
import CreativeBuilderHelpers from '../../CreativeBuilderHelpers';
import CreativeBuilderTypeIcon from '../../CreativeBuilderTypeIcon';
import CreativeBuilderItem from '../../CreativeBuilderItem';
import ReviewButtons from './review-buttons';
import CreativeBuilderBlockExportToCreativeManagement from './export-to-creative-management';

import '../styles/main.scss';

/**
 * CreativeBuilderBlock
 * Render a creative builder block
 * Displays a block in the creative builder. This includes the comment section and edit buttons.
 * This component is mostly a display component.
 */
class CreativeBuilderBlock extends React.Component {
    static defaultProps = {
        data: {}
    };

    constructor(props) {
        super(props);

        this.state = {
            commentReviewIsOpen: false,
            itemHovered: false,
            subsetActive: props.data.subsets ? props.data.subsets[0] : 'main'
        };
    }

    /**
     * Open menu of a block
     * @param {*} event
     */
    onMenuOpen = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    onMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    onSetSubsetActive = (subsetItem) => {
        this.setState({ subsetActive: subsetItem });
    };

    /**
     * Strip the title data
     */
    getTitle = () => {
        const { data } = this.props;
        let title = data.title;
        if (!title) {
            title = '';
        }
        return title;
    };

    /**
     * Copy the item
     */
    onCopyItem = (item) => {
        this.props.onCopyItem(item);
        this.setState({ anchorEl: null });
    };

    /**
     * Copy the item
     */
    onMoveUp = (item) => {
        this.props.onMoveUp(item);
        this.setState({ anchorEl: null });
    };

    /**
     * Copy the item
     */
    onMoveDown = (item) => {
        this.props.onMoveDown(item);
        this.setState({ anchorEl: null });
    };

    /**
     * Show item confirm dialog
     */
    onRemoveItemConfirmDialog = (item) => {
        this.props.onRemoveItemConfirmDialog(item);
        this.setState({ anchorEl: null });
    };

    /**
     * Open comments
     */
    onOpenComments = () => {
        this.setState({ commentReviewIsOpen: true });
    };

    /**
     * Close comments
     */
    onCloseComments = () => {
        this.setState({ commentReviewIsOpen: false });
    };

    renderSubtitles = () => {
        const { data } = this.props;
        const { assetSetup } = data;
        if (!assetSetup) return;

        const buildTitle = (type, templateIdentifier) => {
            const templateTitle = TemplateHelpers.getTemplateTitle(type, templateIdentifier);
            const templateVersion = TemplateHelpers.getTemplateDesignerVersion(type, templateIdentifier);

            if (!templateTitle) return;

            let title = `${Translation.get('template.types.template', 'common')}: ${templateTitle}`;

            if (isTemplateDesignerTemplateType(type) && templateVersion) {
                title += ` - ${Translation.get('template.version', 'common')}: ${templateVersion}`;
            }

            return title;
        };

        if (assetSetup.frames) {
            const frameKeys = Object.keys(assetSetup.frames);

            const toolTipContent = (
                <div>
                    {frameKeys.map((frameKey, index) => {
                        const frame = assetSetup.frames[frameKey];
                        const { type, templateIdentifier } = frame;

                        return <div key={index}>{buildTitle(type, templateIdentifier)}</div>;
                    })}
                </div>
            );

            return (
                <div className="creative-builder-block__top__subtitle">
                    <Tooltip title={toolTipContent}>
                        <div className="creative-builder-block__top__subtitle__multiple">
                            <div>{Translation.get('template.multipleTemplateUsed', 'common')}</div>
                            <Icon className="creative-builder-block__top__subtitle__multiple__icon">info</Icon>
                        </div>
                    </Tooltip>
                </div>
            );
        }

        const { type, templateIdentifier } = assetSetup;

        return <div className="creative-builder-block__top__subtitle">{buildTitle(type, templateIdentifier)}</div>;
    };

    openTemplate = () => {
        const { data } = this.props;
        const { assetSetup } = data;
        const { type, templateIdentifier } = assetSetup;

        const template = Templates.get(type, templateIdentifier);

        if (!template) return;

        if (isTemplateDesignerTemplateType(type)) {
            window.open('/template-designer?id=' + template.id);
        } else {
            if (isAMV2Enabled()) {
                RouteHelper.openLink('/templates/' + template.id);
                return;
            }

            RouteHelper.openLink('/templates/edit/' + template.id);
        }
    };

    render() {
        const {
            item,
            data,
            onOpenItem,
            model,
            dataModel,
            language,
            defaultFullSize,
            hideControls = false,
            canReview = true,
            reviewInBox = false,
            showFormatTitle = false
        } = this.props;
        const { anchorEl, commentReviewIsOpen = true, subsetActive } = this.state;

        const canOpenTemplate = (() => {
            if (data.assetSetup && data.assetSetup.frames) return false;

            if (!User.hasRight('templateDesigner') && !User.hasRight('templateManagement')) {
                return false;
            }

            return true;
        })();

        const externalPreview = process.env.EXTERNAL_PREVIEW;

        return (
            <div className="creative-builder-block" data-tour-selector="creative-builder-block">
                {!hideControls && (
                    <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.onMenuClose}>
                        {this.props.onRemoveItemConfirmDialog && (
                            <MenuItem onClick={() => this.onRemoveItemConfirmDialog(item)}>{Translation.get('actions.remove', 'common')}</MenuItem>
                        )}
                        {this.props.onCopyItem && <MenuItem onClick={() => this.onCopyItem(item)}>{Translation.get('actions.copy', 'common')}</MenuItem>}
                        {this.props.onMoveUp && <MenuItem onClick={() => this.onMoveUp(item)}>{Translation.get('actions.moveUp', 'common')}</MenuItem>}
                        {this.props.onMoveDown && <MenuItem onClick={() => this.onMoveDown(item)}>{Translation.get('actions.moveDown', 'common')}</MenuItem>}
                        {canOpenTemplate && <MenuItem onClick={() => this.openTemplate()}>{Translation.get('actions.openTemplate', 'common')}</MenuItem>}
                        <CreativeBuilderBlockExportToCreativeManagement item={item} data={data} />
                    </Menu>
                )}

                <div className="creative-builder-block__top">
                    <div className="creative-builder-block__top__type">
                        <CreativeBuilderTypeIcon type={CreativeBuilderHelpers.getTypeIcon(data)} />
                    </div>
                    <div className="creative-builder-block__top__title-container">
                        <Typography variant="h5" component="div">
                            {this.getTitle()}
                        </Typography>
                        {this.renderSubtitles()}
                    </div>

                    {!hideControls && (
                        <div className="creative-builder-block__top__actions-wrapper">
                            <CommentReviewHeader
                                type="button"
                                entity={'creativeBuilder.' + item.uuid}
                                campaignId={EditorData.getId()}
                                displayType="button"
                                onOpen={this.onOpenComments}
                                onClose={this.onCloseComments}
                                size="small"
                            />
                            {onOpenItem && (
                                <IconButton
                                    aria-label="edit"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={() => onOpenItem(item)}
                                    size="small"
                                    data-tour-selector="creative-builder-block__edit">
                                    <Icon>edit</Icon>
                                </IconButton>
                            )}

                            {!externalPreview && (
                                <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={this.onMenuOpen} size="small">
                                    <Icon>more_horiz</Icon>
                                </IconButton>
                            )}
                        </div>
                    )}

                    {!hideControls && reviewInBox && <ReviewButtons item={item} model={model} canReview={canReview} />}
                </div>

                {data.description && (
                    <Alert square severity="info" icon={false}>
                        {data.description}
                    </Alert>
                )}

                {data.subsets && data.subsets.length > 1 && (
                    <div className="creative-builder-block__subsets">
                        <EditorSubsets items={data.subsets} canEdit={false} selected={subsetActive} onSetSubsetActive={this.onSetSubsetActive} />
                    </div>
                )}

                <div className="creative-builder-block__element">
                    <div className="creative-builder-block__element__item-holder">
                        <CreativeBuilderItem
                            showFormatTitle={showFormatTitle}
                            data={data}
                            dataModel={dataModel}
                            item={item}
                            language={language}
                            subsetActive={subsetActive}
                            waitForLoading={false}
                            defaultFullSize={defaultFullSize}
                        />
                    </div>
                    {commentReviewIsOpen && (
                        <div className="creative-builder-block__review">
                            <Comments campaignId={EditorData.getId()} entity={'creativeBuilder.' + item.uuid} />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default CreativeBuilderBlock;
